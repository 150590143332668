import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from '../config/context';
import { Ul } from "../Components/Ul";
import { checkAuth, logout } from '../Utils/auth';
import { apiCall } from '../Utils/api';

export const Header = ({ home, currentUrl }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isAccountMenu, setAccountMenu] = useState(false);
    const [submitting, setSubmiting] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { lang, setLang, app, setApp, isAuth, setAuth } = useAppContext();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const isProperties = location.pathname === `/${lang}/${t("url.properties")}`;
    const isNews = location.pathname === `/${lang}/${t("url.news")}`;
    const [loading, setLoading] = useState(true);
    const handleOpen = () => {
        setIsOpened(!isOpened);
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleChangeLang = (e, lang) => {
        setLang(lang);
    }

    const getAccountMenu = (update = false) => {
        if(!app.blocks?.account_menu || update){
            apiCall(`${process.env.REACT_APP_API_URL}/es/configuration?var=${t('vars.account-menu', { lng: lang })}`, 'get').then(res => {
                app.blocks[t('vars.account-menu', { lng: lang })] = res.value;
                setApp(app);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        checkAuth().then(res => {
            if (res) {
                setAuth(true);
            } else {
                setAuth(false);
            }
        });
        if (loading) {
            getAccountMenu()
        }
    }, []);

    useEffect(() => {
        if(i18n.language !== lang){
            getAccountMenu(true);
        }
    }, [lang]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);

    useEffect(() => {
        setIsOpened(false);
        setAccountMenu(false);
    }, [location]);

    const handleLogout = async () => {
        setSubmiting(true);
        const res = await logout();
        if (res.status === "error") {
            alert(res.message);
        } else {
            setAuth(false);
            setAccountMenu(false);
        }
        setSubmiting(false);
    }

    const translateRoute = lng => {
        if (home) {
            return `/${lng}`;
        }

        let route = currentUrl.split('/')
        route.splice(0, 2);
        route = route.map(r => {
            return i18n.exists(`url.${r}`, { lng }) ? t(`url.${r}`, { lng }) : r;
        });

        return `/${lng}/${route.join('/')}`;
    }

    const getHeaderClass = () => {
        if (!home && (!isProperties && !isNews)) {
            return "_301_alter_fix"
        }
        return ''
    }

    return (
        <header className={`${scrollPosition >= 10 ? '_301_active' : ''}`}>
            <div className={`_301_toolbar ${getHeaderClass()} ${scrollPosition >= 10 || isOpened ? '_301_alter' : ''}`}>
                <div className="_301_content _301_flex">
                    <div className="_301_col _301_account">
                        <div className="_301_option">
                            <div className="_301_icon" onClick={() => setAccountMenu(!isAccountMenu)}><i className="fa-solid fa-key"></i></div>
                            <label> {t('my-account')}  </label>
                            <div className={`_301_floating_block ${isAccountMenu ? '_301_active' : ''}`}>
                                {app.blocks[t('vars.account-menu', { lng: lang })] && <div dangerouslySetInnerHTML={{__html: app.blocks[t('vars.account-menu', { lng: lang })]}} />}
                                {
                                    isAuth &&
                                    <>
                                        <div className="_301_row">
                                            <button className="_301_cta" disabled={submitting} onClick={handleLogout}>{t('logout')}</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <a href="https://pagos.palomma.com/abadfaciolince" target="_blank" rel="noreferrer">
                            <div className="_301_call_cta">
                                <i className="fa-solid fa-dollar"></i>
                                <label> {t('payment_link')}</label>

                            </div>
                        </a>
                    </div>
                    <div className="_301_col _301_brand">
                        <Link to={`/${lang}`}>
                            <div className="_301_logo">
                            </div>
                        </Link>
                    </div>
                    <div className="_301_col _301_menu">
                        <Link to={`/${lang}/${t('url.favorites')}`}>
                            <div className="_301_call_cta _301_fav">
                                <i className="fa-solid fa-heart"></i>
                            </div>
                        </Link>
                        <div className="_301_option">
                            <label>{t('menu')} </label>
                            <div className={`_301_icon ${isOpened ? '_301_close' : ""}`} onClick={handleOpen}><i className="fa-solid fa-bars"></i><i className="fa-solid fa-xmark"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`_301_menu_view ${isOpened ? '_301_show' : ""}`}>
                <div className="_301_content">
                    <div className="_301_category">
                        <Ul
                            head={t('company')}
                            options={[
                                {
                                    href: `/${lang}/${t('url.about-us')}`,
                                    title: t("about-us"),
                                },
                                {
                                    href: `/${lang}/${t('url.news')}`,
                                    title: t("news"),
                                },
                            ]}
                        />
                    </div>
                    <div className="_301_category">
                        <Ul
                            head={t('properties')}
                            options={[
                                {
                                    href: `/${lang}/${t('url.how-to-rent')}`,
                                    title: t("how-to-rent"),
                                },
                                {
                                    href: `/${lang}/${t('url.properties')}?property-status=rent`,
                                    title: t("rent"),
                                },
                                {
                                    href: `/${lang}/${t('url.properties')}`,
                                    title: t('sell'),
                                },
                                {
                                    href: `/${lang}/${t('url.properties')}?property-type=11`,
                                    title: t("projects"),
                                }
                            ]}
                        />
                    </div>
                    <div className="_301_category">
                        <Ul
                            head="Trámites"
                            options={[
                                {
                                    href: `/${lang}/${t('url.postulate')}`,
                                    title: t("postulate")
                                },
                                {
                                    href: `/${lang}/${t('url.contracts')}`,
                                    title: t("contracts")
                                },
                                {
                                    href: `/${lang}/${t('url.repair')}`,
                                    title: t("repairs")
                                },
                                {
                                    href: "https://pagos.palomma.com/abadfaciolince",
                                    title: t("online-payments"),
                                    external: true
                                },
                                {
                                    href: `/${lang}/${t('url.pqr')}`,
                                    title: t("pqrs")
                                }
                            ]}
                        />
                    </div>
                    <div className="_301_category">
                        <h3>{t('lang')}</h3>
                        <ul className="_301_hover">
                            <li>
                                <Link to={translateRoute('es')} className={lang === "es" ? "_301_lang_active" : ""} onClick={e => handleChangeLang(e, 'es')}>Español</Link>
                            </li>
                            <li>
                                <Link to={translateRoute('en')} className={lang === "en" ? "_301_lang_active" : ""} onClick={e => handleChangeLang(e, 'en')}>English</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}