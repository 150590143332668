import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { useLocation } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import './App.css';
import { Footer } from './Blocks/Footer';
import { Header } from './Blocks/Header';
import { AppContextProvider } from './config/context';
import { LangRouter } from './config/routes';
import ErrorBoundary from './Components/ErrorHandler';

function App() {
	const { pathname } = useLocation();

	return (
		<>
			<ErrorBoundary fallback={
				<>
					<p style={{textAlign: "center"}}>Ha ocurrido un error.</p>
					<p style={{textAlign: "center"}}>Notificar a 301.</p>
				</>
			} pathname={pathname}>
				<AppContextProvider>
					<Header home={['/es', '/en'].includes(pathname)} currentUrl={pathname} />
					<GoogleReCaptchaProvider
						useRecaptchaNet
						reCaptchaKey={process.env.REACT_APP_CATPCHA_KEY}
						scriptProps={{ async: true, defer: true, appendTo: 'body' }}
					>
						<LangRouter />
					</GoogleReCaptchaProvider>
					<Footer />
				</AppContextProvider>
			</ErrorBoundary>
		</>
	);
}

export default App;
