import { createContext, useEffect, useState, lazy, Suspense, Navigate, Switch, Redirect } from "react";
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";

export const ReactLazyPreload = importStatement => {
    const Component = lazy(importStatement);
    Component.preload = importStatement;
    return Component;
  };

const Home = ReactLazyPreload(() => import('../Pages/Home'));
const Content = ReactLazyPreload(() => import('../Pages/Content'));
const Property = ReactLazyPreload(() => import('../Pages/Property'));
const Properties = ReactLazyPreload(() => import('../Pages/Properties'));
const News = ReactLazyPreload(() => import('../Pages/News'));
const Article = ReactLazyPreload(() => import('../Pages/Article'));
const PQR = ReactLazyPreload(() => import('../Pages/PQR'));
const Repair = ReactLazyPreload(() => import('../Pages/Repair'));
const Postulate = ReactLazyPreload(() => import('../Pages/Postulate'));
const Contracts = ReactLazyPreload(() => import('../Pages/Contracts'));
const Login = ReactLazyPreload(() => import('../Pages/Login'));
const Favorite = ReactLazyPreload(() => import('../Pages/Favorite'));

export const LocaleContext = createContext({
    locale: '',
    setLocale: (newLocale) => { },
});

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        })
    }, [pathname]);
  
    return null;
}


export const LangRouter = () => {
    const { t, i18n } = useTranslation();
    const { pathname, search, hash } = useLocation();
    const navigate = useNavigate();
    const defaulLocale = "es";
    const avaliableLocales = ['en', 'es'];
    const pathnameLocale = pathname.substring(1, 3).toLocaleLowerCase();
    const [locale, setLocale] = useState(defaulLocale);

    useEffect(() => {
        if (avaliableLocales.includes(pathnameLocale)) {
            updateLocale(pathnameLocale);
        } else if (pathname === "/") {
            updateLocale(defaulLocale);
        }else if(!avaliableLocales.includes(pathnameLocale)){
            navigate(defaulLocale);
        }
    }, [pathname]);

    useEffect(() => {
        if(defaulLocale !== pathnameLocale){
            let lang = defaulLocale;
            if (avaliableLocales.includes(pathnameLocale)) {
                lang = pathnameLocale;
                setLanguageHandler(lang)
            } else if (pathname === "/") {
                setLanguageHandler(lang)
            }
        }
    }, [locale]);

    const setLanguageHandler = (lang) => {
        if (lang === 'en') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('es');
        }
    };

    const updateLocale = (newLocale) => {
        const newPath = `/${newLocale}` + pathname.substring(3);

        if (locale !== newLocale) {
            if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
                navigate(`${newLocale}`);
            } else {
                navigate(`${newPath}${hash}${search}`);
            }
            setLocale(newLocale);
        } else if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
            //if (isAuthenticated()) {
            navigate(`${newLocale}`);
            /*} else {
                navigate(getLoginPageUrl(newLocale));
            }*/
        }
    };

    return (
        <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
            <ScrollToTop />
            <Suspense fallback={<div/>}>
                <Routes>
                    <Route
                        path={`/${locale}/${t('url.data-policy')}`}
                        element={<Content url={t('url.data-policy')} title={t('data-policy')} keyVar={t('vars.data-policy')} />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.work-with-us')}`}
                        element={<Content url={t('url.work-with-us')} title={t('work-with-us')} keyVar={t('vars.work-with-us')} />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.how-to-rent')}`}
                        element={<Content url={t('url.how-to-rent')} title={t('how-to-rent')} keyVar={t('vars.how-to-rent')} />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.about-us')}`}
                        element={<Content url={t('url.about-us')} title={t('about-us')} keyVar={t('vars.about-us')} />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.tenants')}`}
                        element={<Content url={t('url.tenants')} title={t('tenants')} keyVar={t('vars.tenants')} mainClass={"_301_fm_call"}/>}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.owners')}`}
                        element={<Content url={t('url.owners')} title={t('owners')} keyVar={t('vars.owners')} mainClass={"_301_fm_call"}/>}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.favorites')}/:uuid?`}
                        element={<Favorite url={t('url.favorites')} title={t('favorites')} />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.advisors')}`}
                        element={<Login />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.properties')}`}
                        element={<Properties />}
                        exact
                    />
                    <Route
                        path={`/${locale}/${t('url.properties')}/:code`}
                        element={<Property />}
                    />
                    <Route
                        path={`/${locale}/${t('url.news')}`}
                        element={<News />}
                    />
                    <Route
                        path={`/${locale}/${t('url.news')}/:slug`}
                        element={<Article />}
                    />
                    <Route
                        path={`/${locale}/${t('url.pqr')}`}
                        element={<PQR mainClass="_301_fm_call" />}
                    />
                    <Route
                        path={`/${locale}/${t('url.repair')}`}
                        element={<Repair mainClass="_301_fm_call" />}
                    />
                    <Route
                        path={`/${locale}/${t('url.postulate')}`}
                        element={<Postulate mainClass="_301_fm_call" />}
                    />
                    <Route
                        path={`/${locale}/${t('url.contracts')}`}
                        element={<Contracts mainClass="_301_fm_call" />}
                    />
                    <Route
                        path={`/${locale}`}
                        element={<Home />}
                        index
                    />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Suspense>
        </LocaleContext.Provider>
    );
}