import * as React from 'react';
import { apiCall } from '../Utils/api';
import { Link } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.log(
            error,
            info.componentStack,
        );
        apiCall(`${process.env.REACT_APP_API_URL}/es/error`, 'post',[error,info, this.props.pathname, window.navigator.userAgent]).then(res => {
            console.log("logged"); 
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="_301_col" style={{margin: "100px"}}>
                        <Link to={`/es`}>
                            <div className="_301_logo_alter">
                            </div>
                        </Link>
                    {this.props.fallback}
                </div>
            );
        }

        return this.props.children;
    }
}
