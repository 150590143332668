import { apiCall } from "./api";

const truncate = (input, length = 25) => WordCount(input) > length ? `${input.split(" ").splice(0,length).join(" ")}...` : input;

const formatPrice = (price) => {
    let currency = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return currency.format(price);
}

const formatNumber = (number) => {
    return number.toLocaleString("en-US");
}

const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function WordCount(str) { 
    if(str){
        return str.split(" ").length;
    }else{
        return "";
    }
}

function getCookie(name) {
    return localStorage.getItem(name)
}

async function addToFavorite(code){
    let cookie = getCookie('uuid');
    if (!cookie) {
        singBrowser();
        cookie = getCookie('uuid');
    }
    let res = await apiCall(`${process.env.REACT_APP_API_URL}/es/wish`, 'post', {guid: cookie, code});
    if(res.status === 'success'){
        return true;
    }
    return false;
}

function singBrowser(){
    let cookie = localStorage.getItem('uuid');
    if (!cookie) {
        localStorage.setItem("uuid", uuidv4());
    }
}

async function removeFromFavorite(code){
    let cookie = getCookie('uuid');
    let res = await apiCall(`${process.env.REACT_APP_API_URL}/es/wish?code=${code}&guid=${cookie}`, 'delete', {});
    if(res.status === 'success'){
        return true;
    }
    return false;
}

async function hasFavorite(code){
    let cookie = getCookie('uuid');
    let res = await apiCall(`${process.env.REACT_APP_API_URL}/es/wish/check?code=${code}&guid=${cookie}`, 'get', {})
        
    return res;
}

function uuidv4() {
return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
);
}
  

export {
    truncate,
    formatPrice,
    formatNumber,
    validateEmail,
    WordCount,
    addToFavorite,
    getCookie,
    hasFavorite,
    removeFromFavorite,
    singBrowser
}